* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

html {
  background: #000;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}
