.pulse {
  animation: pulse-red 2s infinite;
  border-radius: 500px;
  filter: invert(11%) sepia(93%) saturate(5215%) hue-rotate(0deg)
    brightness(120%) contrast(119%);
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #ff0000b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
