.categories-container::-webkit-scrollbar {
  /* display: none; */
}

/* width */
.categories-container::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.categories-container:hover::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.categories-container::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.categories-container::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
.categories-container::-webkit-scrollbar-thumb:hover {
  background: #222;
}
